<template>
    <footer>
        <div class="mt-5 pt-5 has-background-primary-light">
            <div class="container pr-3 pl-3">
                <div class="columns has-text-centered">
                    <div class="column is-4"> 
                        <div class="content has-text-primary">      
                            <h5 class="is-title is-size-5 has-text-primary">{{ $t('footer.contact.title') }}</h5>
                            <!-- <p><b-icon icon="map-marker-alt" class="mr-2"></b-icon>Lorem Ipsum, XXXX, City</p>
                            <p><b-icon icon="phone" class="mr-2"></b-icon>+34 600 70 07 00</p> -->
                            <p><router-link to="/contact"><b-icon icon="envelope" class="mr-2"></b-icon>{{ $t('footer.contact.contact_us') }}</router-link></p>
                        </div>
                    </div>
                    
                    <div class="column is-4">
                        <div class="content has-text-primary">
                            <h5 class="is-title is-size-5 has-text-primary">{{ $t('footer.legal.title') }}</h5>
                            <p><router-link to="/legal">{{ $t('legal.notice') }}</router-link></p>
                            <p><router-link to="/privacy">{{ $t('legal.privacy') }}</router-link></p>
                            <p><router-link to="/cookies">{{ $t('legal.cookies') }}</router-link></p>
                        </div>
                    </div>

                    <div class="column is-4"> 
                        <img
                            src="@/assets/img/Imagotipo 3@0.5x.png"
                            alt="Lightweight UI components for Vue.js based on Bulma"
                            >
                        <div class="content has-text-primary">
                            <p class="is-size-7">&copy; Open My Art &bull; 2023-2025 | Designed by <a target="_blank" href="https://www.codingfab.com">codingfab</a></p>
                        </div>
                    </div>


                </div>                
            </div>
        </div>
    </footer>
    
</template>

<script>

export default {
    name: 'Footer',
}
</script>
